.privacyPolicyBanner {
    --bottomMarginMobile: 66px;
    --bottomMarginTablet: 16px;
    --bottomMarginPC: 16px;
    /* Width of one columns is: (Page width - margin * 2 - gutter * (columns amount - 1)) / columns amount */
    --columnWidthTablet: calc((min(1024px, 100%) - 64px - 32px * 7) / 8);
    --columnWidthPC: calc((min(1280px, 100%) - 64px - 32px * 11) / 12);

    bottom: var(--bottomMarginMobile);
    position: fixed;
    z-index: 11;

    @media (--snow-tablet) {
        margin-left: -16px;
    }
}

.action {
    margin-top: 4px;
    width: 100%;
}

@media (--snow-tablet) {
    .privacyPolicyBanner {
        bottom: var(--bottomMarginTablet);
        width: calc(3 * var(--columnWidthTablet) + 2 * 32px); /* 3 * column width + 2 * gutter */
    }
}

@media (--snow-desktop) {
    .privacyPolicyBanner {
        bottom: var(--bottomMarginPC);
        width: calc(4 * var(--columnWidthPC) + 3 * 32px); /* 4 * column width + 3 * gutter */
        min-width: 340px; /* Max width of Tablet view. It needs because of width jump between Tablet and PC rules. */
    }
}
